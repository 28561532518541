import React from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { Router } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles'

import { history } from 'router/history'
import { RouterView } from 'router/router'

import { AuthProvider } from 'contexts/auth/AuthContext'
import { NotificationProvider } from 'contexts/notification/NotificationProvider'
// TODO: Good candidate for state management
import { RefillProvider } from 'contexts/RefillContext/RefillContext'
import { VehicleProvider } from 'contexts/VehicleContext/VehicleContext'
import { theme } from 'util/theme/theme'

import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Navbar } from 'components/Navbar/Navbar'

import styles from './App.module.scss'

export const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationProvider>
            <AuthProvider>
              <Router history={history}>
                <Navbar />
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Container maxWidth="sm" className={styles.rootContainer}>
                    <RefillProvider>
                      <VehicleProvider>
                        <RouterView />
                      </VehicleProvider>
                    </RefillProvider>
                  </Container>
                </ErrorBoundary>
              </Router>
            </AuthProvider>
          </NotificationProvider>
        </ThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  )
}
