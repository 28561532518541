import React from 'react'

import styles from './ErrorFallback.module.scss'

interface Props {
  error: Error
  resetErrorBoundary: any
}

export const ErrorFallback: React.FC<Props> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" className={styles.root}>
      <h2 className={styles.title}>Something went wrong:</h2>
      <div className={styles.contentContainer}>
        <pre className={styles.message}>{error.message}</pre>
        <button className={styles.resetButton} onClick={resetErrorBoundary}>
          Try again
        </button>
        <h4>If the problem persists, try again later or try refreshing the page</h4>
      </div>
    </div>
  )
}
