export * from './persist'
export * from './date'
export * from './chart'

/**
 * Group objects by key.
 * Useful for charts
 * 
 * ie.
 * console.log(groupBy(['one', 'two', 'three'], 'length'))

// => {3: ["one", "two"], 5: ["three"]}
 */
export const groupBy = (arr: any[], key: string): Record<string, any> => {
  return arr.reduce((acc, curr) => {
    ;(acc[curr[key]] = acc[curr[key]] || []).push(curr)
    return acc
  }, {})
}

/**
 * Generic type guard for objects
 */
export const isOfType = <T>(obj: Record<string, any>, prop: keyof T): obj is T => {
  return (obj as T)[prop] !== undefined
}

// export const isOfType = <T>(obj: any, prop: keyof T, propType?: type): obj is T => {
// return (obj as T)[prop] !== undefined && PropType && isOfType<PropType>(obj[prop])
// export const basicIsOfType = <T>(obj: any, propType): obj is T => {
//   return T === propType
// }

/**
 * Create delay, ie. simulate API call
 */
export const delay = (ms: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Transform a path to a named Component
 * Assumes adhering to a naming convention
 *
 * ie. /not_found => NotFound
 */
export const getModuleName = (path: string): string => {
  return path
    .slice(1)
    .split('_')
    .map((s) => s.charAt(0).toLocaleUpperCase() + s.slice(1))
    .join('')
}
