import React from 'react'

import { useSnackbar } from 'notistack'

import { fetchRefills, deleteRefill } from 'adapters/refill/RefillAdapter'
import { apiReducer, apiInitialState, doLoadHelper, doDeleteHelper } from 'util/helpers/apiReducer'
import { IRefill } from 'util/interfaces/refill/refill.interface'
import { makeLogger } from 'util/logger'

const logger = makeLogger({ label: 'RefillContext' })

export const RefillContext = React.createContext<any>({})

export const RefillProvider: React.FC<any> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const data = useGenerateRefills({ enqueueSnackbar })
  return <RefillContext.Provider value={data}>{children}</RefillContext.Provider>
}

export const useRefills: any = () => {
  const context = React.useContext(RefillContext)
  if (context === undefined) {
    throw new Error('useRefills must be used within a RefillProvider')
  }

  return context
}

const useGenerateRefills = ({ enqueueSnackbar }: any): any => {
  const [state, dispatch] = React.useReducer(apiReducer<IRefill>(), apiInitialState)
  const [dirty, setDirty] = React.useState(false)
  const { data, done, loading, ok, error } = state

  const loadArgs = {
    dispatch,
    apiFunc: fetchRefills,
    log: { logger, notify: { notify: enqueueSnackbar } },
  }

  const doLoad = (): Promise<void> | undefined => {
    if (!loading && (!ok || dirty)) {
      setDirty(false)
      return doLoadHelper<IRefill>(loadArgs)
    }
  }

  const forceLoad = (): Promise<void> => doLoadHelper<IRefill>(loadArgs)

  const doDelete = (id: number) => (): Promise<void> => {
    return doDeleteHelper({
      dispatch,
      apiFunc: deleteRefill,
      log: { logger, notify: { notify: enqueueSnackbar } },
      params: { id },
    })
  }

  const markDirty = (): void => {
    setDirty(true)
  }

  return {
    data,
    done,
    loading,
    ok,
    error,
    doLoad,
    forceLoad,
    doDelete,
    markDirty,
  }
}
