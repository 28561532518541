/* eslint-disable no-console */
import { createLogger, format, transports, Logger } from 'winston'

import util from 'util'

import { ignoreErrorList } from './ignoreErrorList'
import { getReference } from './stackTrace'

export const makeLogger = (meta: Meta): Logger => {
  return createLogger({
    defaultMeta: { app: 'refill-fe' },
    level: process.env.REACT_APP_LOG_LEVEL || 'info',
    transports: [
      new transports.Console({
        format: format.combine(
          format.colorize({ all: true }),
          format.timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
          utilFormatter(),
          format.label({ label: meta.label }),
          format.printf(({ level, message, label, timestamp }) => {
            const reference = getReference()
            return `${timestamp} ${label || '-'} ${level}: ${message} | ${reference}`
          })
          // TODO: Check for update, this is not working?
          // format.errors({ stack: true }),
        ),
        handleExceptions: true,
        // handleRejections: true,
      }),
    ],
  })
}

const origConsoleError = console.error

console.error = (...args) => {
  const isIgnored = ignoreErrorList.find(({ tag, name }) => {
    return tag.test(args[0]) && args[1]?.includes(name)
  })
  if (!isIgnored) {
    origConsoleError.call(console, ...args)
  }
}

type Meta = {
  label: string
}

const transform = (info: any, opts: any) => {
  const args = info[Symbol.for('splat')]
  if (args) {
    info.message = util.format(info.message, ...args)
  }
  return info
}

const utilFormatter = () => {
  return { transform }
}
