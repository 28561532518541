export const DATE_FORMAT = {
  MACHINE: 'yyyy-MM-dd',
  HUMAN: 'EEE do MMM, yyyy',
  SHORT_HUMAN: 'dd/MM/yyyy',
  YEAR_ONLY: 'yyyy',
}

export const TIME_FORMAT = {
  T24: 'HH:mm',
}
