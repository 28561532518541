import React from 'react'

import { Link } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'

import { DRAWER_ROUTES, USER_ROUTES } from 'router/routes'

import { useAuth } from 'contexts/auth/AuthContext'
import { IRoute } from 'util/interfaces/router/routes.interface'

import { NavDrawer } from 'components/NavDrawer/NavDrawer'

import styles from './Navbar.module.scss'

export const Navbar = (): JSX.Element => {
  const { user, loggedIn } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
  const openMenu = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent): void => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpenDrawer(open)
    }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={styles.root}>
      <AppBar position="fixed" className={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={styles.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={styles.title}>
            Refuel
          </Typography>
          <div>
            <Button
              aria-label="user menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              startIcon={<AccountCircle />}
            >
              {loggedIn && user.name}
              {!loggedIn && 'Account'}
            </Button>
          </div>
          <NavDrawer open={openDrawer} toggleDrawer={toggleDrawer} routes={DRAWER_ROUTES} />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openMenu}
            onClose={handleClose}
          >
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>*/}
            {USER_ROUTES.filter((route) => {
              return (loggedIn && !route.isPublic) || (!loggedIn && route.isPublic)
            }).map(({ path, title, icon: Icon }: IRoute) => {
              return (
                <Link to={path} key={path}>
                  <MenuItem onClick={handleClose}>
                    <Icon fontSize="small" className={styles.menuIcon} />
                    <Typography variant="inherit">{title}</Typography>
                  </MenuItem>
                </Link>
              )
            })}
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}
