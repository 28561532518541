import React, { Suspense, useEffect } from 'react'

import { Switch, Route } from 'react-router-dom'

import { useAuth } from 'contexts/auth/AuthContext'
import { IRoute } from 'util/interfaces/router/routes.interface'
import { makeLogger } from 'util/logger'

import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon'

import { CatchAll } from './catchAll'
import { PrivateRoute } from './privateRoute'
import { ROUTES, NOT_FOUND_ROUTE } from './routes'

const logger = makeLogger({ label: 'router' })

export const RouterView: React.FC = () => {
  const { getXSRFToken } = useAuth()

  useEffect(() => {
    const getToken = async () => {
      const { ok, message } = await getXSRFToken()
      if (!ok) {
        logger.verbose('XSRF Token error', message)
      }
    }
    getToken()
  }, [getXSRFToken])

  return (
    <Suspense fallback={<LoadingIcon variant="full" />}>
      <Switch>
        {ROUTES.map(
          ({
            path,
            redirect = '',
            noExact = false,
            isPublic = false,
            name,
            title = '',
          }: IRoute) => {
            const Page = React.lazy(() => {
              return import(
                /* webpackChunkName: "LazyPage" */ `../pages${path === '/' ? '' : path}/${name}`
              ).then((module) => ({
                default: module[name],
              }))
            })
            if (path === NOT_FOUND_ROUTE) {
              return <Route key={path} path={path} exact={!noExact} render={() => <Page />} />
            }
            if (!isPublic) {
              return (
                <PrivateRoute
                  key={path}
                  exact={!noExact}
                  path={path}
                  Component={Page}
                  componentProps={{
                    title,
                  }}
                />
              )
            } else {
              return (
                <Route
                  key={path}
                  exact={!noExact}
                  path={path}
                  render={(props) => <Page {...props} title={title} />}
                />
              )
            }
          }
        )}
        <CatchAll />
      </Switch>
    </Suspense>
  )
}
