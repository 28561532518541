type IPersist = string | number | any[] | Record<string, any> | null | undefined

const setItem = (key: string, value: IPersist): void => {
  const storedValue = JSON.stringify(value)

  window.localStorage.setItem(key, storedValue)
}

const getItem = <T>(key: string): T => {
  const retrievedValue = window.localStorage.getItem(key)

  if (retrievedValue) {
    const obj: T = JSON.parse(retrievedValue)
    return obj
  } else {
    throw new Error(`Storage: Value cannot be found for key: ${key}`)
  }
}

export const persist = {
  setItem,
  getItem,
}
