import { Ajax } from 'adapters/ajax/AjaxAdapter'
import { BASE_URL } from 'adapters/constants'
import { ApiError } from 'util/interfaces/error'
import { IRefillReqDTO } from 'util/interfaces/refill/refillReq.dto'
import { IResponseBody } from 'util/interfaces/response/responseBody.interface'
import { IValidationResponse } from 'util/interfaces/response/validationResponse.interface'
import { makeLogger } from 'util/logger'

const logger = makeLogger({ label: 'refillAdapter' })

const REFILL_URL = `${BASE_URL}/refill`

export const fetchRefills = async (): Promise<IResponseBody> => {
  try {
    const res = await Ajax.fetchJSON<IResponseBody>(REFILL_URL)

    if (res) {
      logger.verbose('refills received ok')
      return res
    } else {
      const message = 'Getting refills failed - api'
      logger.error(message, res)
      throw new ApiError(message)
    }
  } catch (err) {
    const errorResponse: Record<string, any> = { ok: false, message: err.message }
    return errorResponse as IResponseBody
  }
}

export const createRefill = async (
  refill: IRefillReqDTO
): Promise<IValidationResponse | IResponseBody> => {
  try {
    const res = await Ajax.post<IRefillReqDTO, IValidationResponse | IResponseBody>(
      REFILL_URL,
      refill
    )

    logger.debug('res => ', res)
    if (res) {
      logger.verbose('create refill ok')
    } else {
      const message = 'Create refill failed - api'
      logger.error(message, res)
      throw new ApiError(message)
    }
    return res
  } catch (err) {
    const errorResponse: Record<string, any> = { ok: false, message: err.message }
    if (err.response?.type === 'VALIDATION') {
      errorResponse.errors = err.response.errors
      return errorResponse as IValidationResponse
    }
    return errorResponse as IResponseBody
  }
}

export const deleteRefill = async (id: number): Promise<IResponseBody> => {
  try {
    // const params = new URLSearchParams({ id: id.toString() })
    // const res = await Ajax.delete<IResponseBody>(REFILL_URL, refill)
    const res = await Ajax.delete<IResponseBody>(`${REFILL_URL}/${id}`)

    if (res) {
      logger.verbose('delete refill ok')
    } else {
      const message = 'Delete refill failed - api'
      logger.error(message, res)
      throw new ApiError(message)
    }
    return res
  } catch (err) {
    const errorResponse: Record<string, any> = { ok: false, message: err.message }
    return errorResponse as IResponseBody
  }
}
