import React from 'react'

import cls from 'classnames'

import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'

import styles from './LoadingIcon.module.scss'

interface IProps {
  variant?: 'full' | 'box' | 'boxCenter' | 'float'
  anchor?: 'anchorRight' | 'anchorLeft'
  className?: Record<string, any>
}

export const LoadingIcon = ({ variant, anchor, className }: IProps): JSX.Element => {
  return (
    <div className={cls(className, variant && styles[variant], anchor && styles[anchor])}>
      <LocalGasStationIcon className={styles.icon} />
    </div>
  )
}
