import React, { useState, useContext, useCallback, createContext } from 'react'

import { useSnackbar } from 'notistack'

import { history } from 'router/history'
import { LOGIN_CB_ROUTE, LOGOUT_CB_ROUTE } from 'router/routes'

import {
  fetchXSRFToken,
  fetchLogin,
  fetchLogout,
  fetchIsAuthenticated,
} from 'adapters/auth/AuthAdapter'
import { ERRORS } from 'util/constants/errors'
import { persist } from 'util/helpers'
import { IResponseBody } from 'util/interfaces/response/responseBody.interface'
import { IUser } from 'util/interfaces/user/user.interface'
import { IUserResponseBody } from 'util/interfaces/user/userResponseBody.interface'
import { makeLogger } from 'util/logger'

// Not ideal for keeping state, but React is being dumb
let expiryTimer = 0

const setExpiryTimer = (val) => {
  expiryTimer = val
}

const logger = makeLogger({ label: 'authContext' })

type IAuthContext = {
  user: IUser | null
  loggedIn: boolean
  getXSRFToken: () => Promise<IResponseBody>
  login: (email: string, password: string) => Promise<IResponseBody | undefined>
  register: (name: string, email: string, password: string) => Promise<boolean | undefined>
  logout: () => Promise<IResponseBody | undefined>
  isLoggedIn: () => Promise<boolean>
}

export const AuthContext = createContext<any>({})

export const AuthProvider: React.FC<any> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const auth = useGenerateAuth({ enqueueSnackbar })
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth: any = () => {
  return useContext(AuthContext)
}

const useGenerateAuth = ({ enqueueSnackbar }: any): IAuthContext => {
  const [user, setUser] = useState<IUser | null>(null)
  const [manualLogout, setManualLogout] = useState<boolean>(false)

  // const [expiryTimer, setExpiryTimer] = useState<number>(0)

  const doLogout = useCallback((): void => {
    // Clear timer - no need to do auto logout if logged out
    window.clearTimeout(expiryTimer)
    // Remove persistence first
    persist.setItem('user', null)
    setUser(null)
    // Navigate back to home regardless
    history.push(LOGOUT_CB_ROUTE)
  }, [])

  const getXSRFToken = useCallback(async (): Promise<IResponseBody> => {
    let returnObj
    try {
      const res = await fetchXSRFToken()
      if (res.ok) {
        logger.debug('XSRF token received ok')
        returnObj = res
      } else {
        logger.error('XSRF error - should be unreachable', res)
      }
    } catch (err) {
      logger.warn('XSRF error', err)
      returnObj = err
    }
    return returnObj
  }, [])

  const login = useCallback(
    async (email: string, password: string): Promise<IResponseBody> => {
      let returnObj
      setManualLogout(false)
      try {
        const loginRes = (await fetchLogin(email, password)) as IUserResponseBody
        if (loginRes.user) {
          const to = window.history.state?.state?.from?.pathname || LOGIN_CB_ROUTE

          // Log out after expiry is reached
          const timer = window.setTimeout(() => {
            logger.verbose('Logging out after token expiry')
            doLogout()
            enqueueSnackbar('Logging out after timeout', { variant: 'warning' })
          }, loginRes.expiry * 1000)
          setExpiryTimer(timer)
          logger.debug('Set login expiry', expiryTimer)

          setUser(loginRes.user)
          persist.setItem('user', loginRes.user)
          enqueueSnackbar('Login successful!', { variant: 'success' })
          logger.debug('Set user', loginRes.user)
          if (to) {
            logger.debug('Redirect to url => ', to)
            history.push(to)
            // TODO still needs to return a valid object, until cancelling
            // promise is implemented
            returnObj = { ok: true, message: 'Login successful' }
          }
        } else {
          logger.verbose('Login failure, likely api looks wrong')
          throw new Error(ERRORS.BASE_ERROR)
        }
      } catch (err) {
        enqueueSnackbar('Log in failed', { variant: 'error' })
        logger.warn('Login failure', err)
        returnObj = { ok: false, message: err.message }
      }
      return returnObj
    },
    [doLogout, enqueueSnackbar]
  )

  const isLoggedIn = async () => {
    let nextLoggedIn = false
    try {
      const { isAuth } = await fetchIsAuthenticated()
      // May have timed out or logged out from a different tab
      if (isAuth) {
        logger.silly("Server says, you're ok")
        // User is already set on current session
        if (user) {
          logger.silly('User already set in session')
          nextLoggedIn = true
        } else {
          try {
            logger.verbose('Retrieve stored user')
            const storedUser = persist.getItem<IUser | null>('user')
            // True if logged in on separate tab or refreshing page
            if (storedUser) {
              setUser(storedUser)
              nextLoggedIn = true
            } else {
              logger.verbose('Stored user exists but is falsy')
              logger.verbose('Not logged in')
              nextLoggedIn = false
              enqueueSnackbar('Cannot find stored user, log in again', { variant: 'warning' })
            }
          } catch (err) {
            logger.verbose('Error getting stored user')
            logger.verbose(`err => `, err.message)
            logger.verbose('Not logged in')
            nextLoggedIn = false
            enqueueSnackbar('Cannot find stored user, log in again', { variant: 'warning' })
          }
        }
      } else {
        logger.verbose('Server says, logged out')
        nextLoggedIn = false
        if (!manualLogout) {
          enqueueSnackbar('Session expired, log in again', { variant: 'warning' })
        }
      }
    } catch (err) {
      enqueueSnackbar('Network error, try again later', { variant: 'error' })
      logger.debug(err)
      nextLoggedIn = false
    }
    return nextLoggedIn
  }

  const register = async (name: string, email: string, password: string) => {
    //     setUser(res.user);
    return true
  }

  const logout = useCallback(async (): Promise<IResponseBody> => {
    let returnObj
    setManualLogout(true)
    try {
      const res = (await fetchLogout()) as IResponseBody
      // TODO toast res.message
      if (res.ok) {
        doLogout()
        enqueueSnackbar('Logout successful!', { variant: 'success' })
        returnObj = { ok: true, message: 'Logout successful' }
      } else {
        logger.verbose('Logout failure, likely api looks wrong')
        throw new Error(ERRORS.BASE_ERROR)
      }
    } catch (err) {
      logger.warn('Logout failure', err)
      try {
        doLogout()
      } catch (e) {}
      enqueueSnackbar('Logout successful with errors', { variant: 'warning' })
      returnObj = { ok: false, message: err }
    }
    return returnObj
  }, [doLogout, enqueueSnackbar])

  const loggedIn = !!user

  return {
    user,
    loggedIn,
    getXSRFToken,
    login,
    register,
    logout,
    isLoggedIn,
  }
}
