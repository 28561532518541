import React from 'react'

type State<T> = {
  data: T[]
  done: boolean
  loading: boolean
  ok: boolean
  error: string
}

type Item = {
  id: number | string
}

export const apiInitialState: State<any> = {
  data: [],
  done: false,
  loading: false,
  ok: false,
  error: '',
}

export enum ACTIONS {
  API_OK = 'API_OK',
  API_INIT = 'API_INIT',
  API_ERROR = 'API_ERROR',
  API_DELETE = 'API_DELETE',
}

type Action<T> =
  | { type: ACTIONS.API_OK; data: T[] }
  | { type: ACTIONS.API_INIT }
  | { type: ACTIONS.API_ERROR; error: string }
  | { type: ACTIONS.API_DELETE; id: number }

export const apiReducer =
  <T extends Item>() =>
  (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case ACTIONS.API_INIT:
        return {
          ...state,
          done: false,
          loading: true,
          ok: false,
          error: '',
        }
      case ACTIONS.API_OK:
        return {
          ...state,
          data: action.data,
          done: true,
          loading: false,
          ok: true,
          error: '',
        }
      case ACTIONS.API_ERROR:
        return {
          ...state,
          // data: [],
          done: false,
          loading: false,
          ok: false,
          error: action.error,
        }
      case ACTIONS.API_DELETE:
        return {
          ...state,
          data: state.data.filter((item) => item.id !== action.id),
        }
      default:
        throw new Error(`Unhandled action type`)
    }
  }

type HelperProps<T = any> = {
  dispatch: React.Dispatch<Action<T>>
  apiFunc: (params?) => Promise<any>
  log: {
    logger?: any // logger type
    notify: { notify: any } // enqueueSnackbar type
  }
  params?: any
}

export const doLoadHelper = async <T>({
  dispatch,
  apiFunc,
  log,
}: HelperProps<T>): Promise<void> => {
  log?.logger?.debug('Init load')
  dispatch({
    type: ACTIONS.API_INIT,
  })
  const res = await apiFunc()
  const { ok, message, data } = res
  if (ok) {
    log?.logger?.debug(message)
    dispatch({
      type: ACTIONS.API_OK,
      data: data as T[],
    })
  } else {
    log?.logger?.debug(message)
    dispatch({
      type: ACTIONS.API_ERROR,
      error: message,
    })
  }
}

export const doDeleteHelper = async ({
  dispatch,
  apiFunc,
  params,
  log,
}: HelperProps): Promise<void> => {
  const { id } = params
  log?.logger?.debug('Init delete')
  const res = await apiFunc(id)
  const { ok, message } = res
  if (ok) {
    log?.logger?.verbose(message)
    dispatch({
      type: ACTIONS.API_DELETE,
      id,
    })
    log?.notify?.notify(message, { variant: 'success' })
  } else {
    log?.logger?.verbose(message)
    log?.notify?.notify(`Delete failed - ${message}`, { variant: 'error' })
  }
}
