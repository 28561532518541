import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { useAuth } from 'contexts/auth/AuthContext'

import { LoadingIcon } from 'components/LoadingIcon/LoadingIcon'

import { LOGIN_ROUTE, LOGIN_CB_ROUTE } from './routes'

interface IProps {
  exact?: boolean
  path: string
  Component: any // React.ReactElement
  componentProps: Record<string, any>
}

export const PrivateRoute = ({ Component, componentProps, ...rest }: IProps): JSX.Element => {
  const auth = useAuth()
  const [loading, setLoading] = React.useState(true)
  const [loggedIn, setLoggedIn] = React.useState(false)

  React.useEffect(() => {
    const checkAuth = async () => {
      const isAuth = await auth.isLoggedIn()
      setLoggedIn(isAuth)
      setLoading(false)
    }
    checkAuth()
  })

  if (loading) {
    return <LoadingIcon variant="full" />
  }
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (loggedIn) {
          return <Component {...routeProps} {...componentProps} />
        } else {
          return (
            <Redirect
              to={{
                pathname: LOGIN_ROUTE,
                state: {
                  from: routeProps.location || LOGIN_CB_ROUTE,
                },
              }}
            />
          )
        }
      }}
    />
  )
}
