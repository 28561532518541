import React from 'react'

import { useSnackbar } from 'notistack'

import { fetchVehicles, deleteVehicle } from 'adapters/vehicle/VehicleAdapter'
import { apiReducer, apiInitialState, doLoadHelper, doDeleteHelper } from 'util/helpers/apiReducer'
import { IVehicle } from 'util/interfaces/vehicle/vehicle.interface'
import { makeLogger } from 'util/logger'

const logger = makeLogger({ label: 'vehicleContext' })

export const VehicleContext = React.createContext<any>({})

export const VehicleProvider: React.FC<any> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const data = useGenerateVehicles({ enqueueSnackbar })
  return <VehicleContext.Provider value={data}>{children}</VehicleContext.Provider>
}

export const useVehicles: any = () => {
  return React.useContext(VehicleContext)
}

const useGenerateVehicles = ({ enqueueSnackbar }: any): any => {
  const [state, dispatch] = React.useReducer(apiReducer<IVehicle>(), apiInitialState)
  const [dirty] = React.useState(false)
  // const [dirty, setDirty] = React.useState(false)
  const { data, done, loading, ok, error } = state

  const loadArgs = {
    dispatch,
    apiFunc: fetchVehicles,
    log: { logger, notify: { notify: enqueueSnackbar } },
  }

  const doLoad = (): Promise<void> | undefined => {
    if (!loading && (!ok || dirty)) {
      return doLoadHelper<IVehicle>(loadArgs)
    }
  }

  const forceLoad = (): Promise<void> => doLoadHelper<IVehicle>(loadArgs)

  const doDelete = (id: number) => (): Promise<void> => {
    return doDeleteHelper({
      dispatch,
      apiFunc: deleteVehicle,
      log: { logger, notify: { notify: enqueueSnackbar } },
      params: { id },
    })
  }

  const getVehicleById = (id: number): IVehicle | null => {
    if (!ok) {
      return null
    }
    return data.find((v) => v.id === id) || null
  }

  return {
    data,
    done,
    loading,
    ok,
    error,
    doLoad,
    forceLoad,
    doDelete,
    getVehicleById,
  }
}
