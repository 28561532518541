import React from 'react'

import { SnackbarProvider } from 'notistack'

import Button from '@material-ui/core/Button'

import styles from './NotificationProvider.module.scss'

export const NotificationProvider: React.FC = ({ children }) => {
  const notistackRef: any = React.createRef()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <Button className={styles.action} onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}
