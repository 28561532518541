import { Ajax } from 'adapters/ajax/AjaxAdapter'
import { BASE_URL } from 'adapters/constants'
import { ApiError } from 'util/interfaces/error'
import { IResponseBody } from 'util/interfaces/response/responseBody.interface'
import { IValidationResponse } from 'util/interfaces/response/validationResponse.interface'
import { IVehicleReqDTO } from 'util/interfaces/vehicle/vehicleReq.dto'
import { makeLogger } from 'util/logger'

const logger = makeLogger({ label: 'vehicleAdapter' })

const VEHICLE_URL = `${BASE_URL}/vehicle`

export const fetchVehicles = async (): Promise<IResponseBody> => {
  try {
    const res = await Ajax.fetchJSON<IResponseBody>(VEHICLE_URL)

    if (res) {
      logger.verbose('vehicles received ok')
      return res
    } else {
      const message = 'Getting vehicles failed - api'
      logger.error(message, res)
      throw new ApiError(message)
    }
  } catch (err) {
    const errorResponse: Record<string, any> = { ok: false, message: err.message }
    return errorResponse as IResponseBody
  }
}

export const createVehicle = async (
  vehicle: IVehicleReqDTO
): Promise<IValidationResponse | IResponseBody> => {
  try {
    const res = await Ajax.post<IVehicleReqDTO, IValidationResponse | IResponseBody>(
      VEHICLE_URL,
      vehicle
    )

    logger.debug('res => ', res)
    if (res) {
      logger.verbose('create vehicle ok')
    } else {
      const message = 'Create vehicle failed - api'
      logger.error(message, res)
      throw new ApiError(message)
    }
    return res
  } catch (err) {
    const errorResponse: Record<string, any> = { ok: false, message: err.message }
    if (err.response?.type === 'VALIDATION') {
      errorResponse.errors = err.response.errors
      return errorResponse as IValidationResponse
    }
    return errorResponse as IResponseBody
  }
}

export const deleteVehicle = async (id: number): Promise<IResponseBody> => {
  try {
    // const params = new URLSearchParams({ id: id.toString() })
    // const res = await Ajax.delete<IResponseBody>(VEHICLE_URL, vehicle)
    const res = await Ajax.delete<IResponseBody>(`${VEHICLE_URL}/${id}`)

    if (res) {
      logger.verbose('delete vehicle ok')
    } else {
      const message = 'Delete vehicle failed - api'
      logger.error(message, res)
      throw new ApiError(message)
    }
    return res
  } catch (err) {
    const errorResponse: Record<string, any> = { ok: false, message: err.message }
    return errorResponse as IResponseBody
  }
}
