import Cookies from 'universal-cookie'

import { BASE_URL, XSRF_METHODS } from 'adapters/constants'
import { ERRORS } from 'util/constants/errors'
import { ApiError, NetworkError } from 'util/interfaces/error'
import { makeLogger } from 'util/logger'

const logger = makeLogger({ label: 'Ajax' })
const cookies = new Cookies()

let xsrfToken = ''

export interface AjaxResponse {
  payload?: any
  error?: Error
  headers?: any
}

interface IPrepareOptions extends RequestInit {
  abortControllerSignal?: any
  timeout?: number
  myApi: boolean
}

interface IPrepareOptionsResponse extends RequestInit {
  clearTimer: number
}

interface IRequestOptions extends RequestInit {
  abortControllerSignal?: any
  timeout?: number
  bodyParser: string
}

export class Ajax {
  public static async fetchJSON<T>(url: string, options?: IRequestOptions): Promise<T> {
    return Ajax._fetch(url, { method: 'GET', bodyParser: 'json', ...options })
  }

  public static async post<T, U>(url: string, body: T, options?: IRequestOptions): Promise<U> {
    return Ajax._fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      bodyParser: 'json',
      ...options,
    })
  }

  public static async delete<T>(url: string, options?: IRequestOptions): Promise<T> {
    return Ajax._fetch(url, {
      method: 'DELETE',
      bodyParser: 'json',
      ...options,
    })
  }

  private static async _fetch<T>(url: string, args: IRequestOptions): Promise<T> {
    let start = performance.now()
    const { method, body, abortControllerSignal, timeout, bodyParser } = args
    const myApi = new RegExp(`^${BASE_URL}`).test(url)
    const options = Ajax.prepareOptions({ method, body, abortControllerSignal, timeout, myApi })
    const response: AjaxResponse = {}

    let safeBody: any = null
    try {
      if (options.body) {
        safeBody = JSON.parse(options.body as string)
        // Replace password characters with stars
        if (safeBody.password) {
          safeBody.password = safeBody.password.replaceAll(/./g, '*')
        }
        safeBody = JSON.stringify(safeBody)
      }
    } catch (jsonErr) {
      logger.error(jsonErr)
    }
    try {
      const res = await fetch(url, options)
      const ttfb = Math.round(performance.now() - start)

      // no need to abort, a response has been given
      clearTimeout(options.clearTimer)

      // set a new token from this request
      // TODO: check that we're calling our API. Don't send on external requests
      xsrfToken = cookies.get('XSRF-TOKEN')
      logger.debug('Setting next token', xsrfToken)
      start = performance.now()
      response.headers = {}
      for (const header of res.headers.entries()) {
        response.headers[header[0]] = header[1]
      }
      try {
        if (res?.status === 504) {
          response.error = new NetworkError(ERRORS.HTTP_504)
        } else {
          const resBody = await res[bodyParser]()
          if (res.ok) {
            response.payload = resBody
          } else if (resBody.message) {
            response.error = new ApiError(resBody.message, resBody.response)
          } else {
            response.error = new NetworkError(res.statusText)
          }
        }
      } catch (err) {
        logger.error(
          `${url} | bodyParser failed with ${err}` +
            ` | Wrong content-type: ${response.headers['content-type']}`
        )
        response.error = new ApiError(ERRORS.BASE_ERROR)
      }
      if (safeBody) {
        logger.verbose(
          `${url} | ${safeBody} | ${ttfb} ms | ${Math.round(performance.now() - start)} ms`
        )
      } else {
        logger.verbose(`${url} | ${ttfb} ms | ${Math.round(performance.now() - start)} ms`)
      }
    } catch (err) {
      if (safeBody) {
        logger.error(`${url} | ${safeBody} | ${err.name} | ${err.message}`)
      } else {
        logger.error(`${url} | ${err.name} | ${err.message}`)
      }
      if (err.name && err.name === 'AbortError') {
        logger.debug(err)
      }
      response.error = new NetworkError(ERRORS.BASE_ERROR)
    }
    if (response.error) {
      throw response.error
    } else if (response.payload) {
      return response.payload
    } else {
      logger.verbose('Should be unreachable: response =>', response)
      throw new Error(ERRORS.BASE_ERROR)
    }
  }

  static prepareOptions({
    method,
    body,
    abortControllerSignal,
    timeout,
    myApi,
  }: IPrepareOptions): IPrepareOptionsResponse {
    const headers = {
      Accept: 'application/json',
    }
    const options: IPrepareOptionsResponse = {
      // mode: 'cors',
      headers,
      // credentials: myApi ? 'include' : 'same-origin',
      credentials: 'same-origin',
      method,
      clearTimer: -1,
    }
    if (abortControllerSignal) {
      options.signal = abortControllerSignal
    } else {
      const controller = new AbortController()
      options.signal = controller.signal
      options.clearTimer = window.setTimeout(() => {
        logger.error('Timeout error: 30s')
        controller.abort()
      }, timeout || 30000)
    }
    if (body) {
      options.body = body
      headers['Content-type'] = 'application/json'
    }
    if (myApi && method && XSRF_METHODS.includes(method)) {
      headers['XSRF-Token'] = xsrfToken
    }
    return options
  }
}
