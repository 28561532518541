/* eslint @typescript-eslint/explicit-module-boundary-types: 0 */
export const getReference = () => {
  const stack = new Error('well').stack.split('\n').slice(1)
  const index = stack.findIndex(
    (str) => !str.includes('vendors~') && !str.includes('getReference') && !str.includes('Printf')
  )
  // console.log(`stack[${index}], stack.slice(0, 5) => `, stack[index], stack.slice(index, 5))
  // index = stack.slice(index + 3).findIndex(str => !str.includes('vendors~') && !str.includes('getReference') && !str.includes('Printf'))
  // console.log(`stack[${index}], stack.slice(0, 5) => `, stack[index], stack.slice(index, 5), stack)
  return stack[index]
}
