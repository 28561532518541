import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

// import { green, blue } from '@material-ui/core/colors'
import './globals.module.scss'
import palette from './palette.module.scss'

import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.secondary,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
        },
      },
    },
  })
)
