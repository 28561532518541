import React from 'react'

import { Route, Redirect, useLocation } from 'react-router-dom'

import { NOT_FOUND_ROUTE } from './routes'

export const CatchAll: React.FC = () => {
  const location = useLocation()

  const query = new URLSearchParams({
    from: location.pathname,
  })

  return (
    <Route>
      <Redirect to={`${NOT_FOUND_ROUTE}?${query.toString()}`} />
    </Route>
  )
}
