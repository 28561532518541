import Cookies from 'universal-cookie'

import { Ajax } from 'adapters/ajax/AjaxAdapter'
import { BASE_URL } from 'adapters/constants'
import { ERRORS } from 'util/constants/errors'
import { ApiError } from 'util/interfaces/error/ApiError'
import { NetworkError } from 'util/interfaces/error/NetworkError'
import { ILoginRequest } from 'util/interfaces/login/loginRequest.interface'
import { IApiResponseBody } from 'util/interfaces/response/apiResponseBody.interface'
import { IResponseBody } from 'util/interfaces/response/responseBody.interface'
import { IUserResponseBody } from 'util/interfaces/user/userResponseBody.interface'
import { makeLogger } from 'util/logger'

const logger = makeLogger({ label: 'authAdapter' })

const MODULE_URL = `${BASE_URL}/authentication`
const LOGIN_URL = `${MODULE_URL}/login`
const LOGOUT_URL = `${MODULE_URL}/logout`
const XSRF_TOKEN_URL = `${MODULE_URL}/xsrf-token`
const IS_AUTH_URL = `${MODULE_URL}/is-auth`

const cookies = new Cookies()

export const fetchXSRFToken = async (): Promise<IResponseBody> => {
  try {
    const res = await Ajax.fetchJSON<IApiResponseBody>(XSRF_TOKEN_URL)

    logger.debug('res => ', res)
    if (res.statusCode === 200) {
      const xsrfToken = cookies.get('XSRF-TOKEN')
      logger.verbose('XSRF token received ok', xsrfToken)
      return { ok: true, message: '' }
    } else {
      logger.error('Error: XSRF token failed', res)
      throw new ApiError(ERRORS.BASE_ERROR)
    }
  } catch (err) {
    logger.error('Error: XSRF token failed', err)
    if (err instanceof ApiError) {
      throw err
    } else {
      throw new NetworkError(ERRORS.BASE_ERROR)
    }
  }
}

export const fetchIsAuthenticated = async (): Promise<{ isAuth: boolean }> => {
  try {
    const res = await Ajax.fetchJSON<IApiResponseBody>(IS_AUTH_URL)
    const { statusCode } = res
    logger.debug('Receive checkAuth response', res)
    if (statusCode === 200) {
      // Authorised
      return { isAuth: true }
    } else {
      throw new Error('Unrecognised status code')
    }
  } catch (err) {
    if (err.response) {
      const { statusCode } = err.response
      if (statusCode === 401) {
        // Unauthorised
        return { isAuth: false }
      }
    }
    throw err
  }
}

export const fetchLogin = async (email: string, password: string): Promise<IUserResponseBody> => {
  const body = {
    email,
    password,
  } as ILoginRequest

  const res = await Ajax.post<ILoginRequest, IUserResponseBody>(LOGIN_URL, body)
  logger.verbose('Login success')
  logger.debug('Receive login response', res)

  return res
}

export const fetchLogout = async (): Promise<IResponseBody> => {
  const body = {}
  try {
    const res = await Ajax.post<Record<string, never>, IApiResponseBody | any>(LOGOUT_URL, body)
    logger.debug(`res => `, res)

    if (res.statusCode === 200) {
      logger.verbose('Logout success')
      return { ok: true, message: res.message }
    } else {
      logger.debug('Logout failure, server ok', res)
      throw new ApiError(res.message)
    }
  } catch (err) {
    logger.warn('Logout failure, network error?', err)
    if (err instanceof ApiError) {
      throw err
    } else {
      throw new NetworkError(ERRORS.BASE_ERROR)
    }
  }
}
