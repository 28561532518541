import React from 'react'

import { Link } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import { IRoute } from 'util/interfaces/router/routes.interface'

import styles from './NavDrawer.module.scss'

interface Props {
  open: boolean
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
  routes: IRoute[]
}

export const NavDrawer: React.FC<Props> = ({ open, toggleDrawer, routes }) => {
  const group = (entries) => (
    <List>
      {entries.map(({ title, path, icon: Icon }, index) => {
        return (
          <Link to={path} key={path}>
            <ListItem button>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
        )
      })}
    </List>
  )

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div
        className={styles.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {group(routes.slice(0, 1))}
        <Divider />
        {group(routes.slice(1))}
      </div>
    </SwipeableDrawer>
  )
}
