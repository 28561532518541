import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp'
import DashboardSharpIcon from '@material-ui/icons/DashboardSharp'
import DriveEtaSharpIcon from '@material-ui/icons/DriveEtaSharp'
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp'
import HomeSharpIcon from '@material-ui/icons/HomeSharp'
import HouseSharpIcon from '@material-ui/icons/HouseSharp'
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp'

import { NAV_TYPES } from 'util/interfaces/router/nav_types.enum'
import { IRoute } from 'util/interfaces/router/routes.interface'

const NullCmp = () => null

const HOME = {
  path: '/',
  title: 'Home',
  isPublic: true,
  name: 'HomePage',
  nav: NAV_TYPES.DRAWER,
  icon: HomeSharpIcon,
}
const LOGIN = {
  path: '/login',
  title: 'Login',
  isPublic: true,
  name: 'LoginPage',
  nav: NAV_TYPES.USER,
  icon: ExitToAppSharpIcon,
}
const LOGOUT = {
  path: '/logout',
  title: 'Log out',
  isPublic: false,
  name: 'LogoutPage',
  nav: NAV_TYPES.USER,
  icon: ExitToAppSharpIcon,
}
const REFILL_HOME = {
  path: '/refill',
  title: 'Refill',
  name: 'RefillHomePage',
  isPublic: false,
  nav: NAV_TYPES.NONE,
  icon: NullCmp,
  redirect: '/refill/dashboard',
  routes: [
    {
      path: '/add',
      title: 'Add refill',
      name: 'RefillAddPage',
      nav: NAV_TYPES.DRAWER,
      icon: AddBoxSharpIcon,
    },
    {
      path: '/dashboard',
      title: 'Dashboard',
      name: 'DashboardPage',
      isPublic: false,
      nav: NAV_TYPES.DRAWER,
      icon: DashboardSharpIcon,
    },
    {
      path: '/list',
      title: 'View history',
      name: 'RefillListPage',
      nav: NAV_TYPES.DRAWER,
      icon: ListAltSharpIcon,
    },
    {
      path: '/edit',
      title: 'Edit',
      name: 'RefillEditPage',
      nav: NAV_TYPES.NONE,
      icon: NullCmp,
    },
  ],
}
const VEHICLE_HOME = {
  path: '/vehicle',
  title: 'Vehicle',
  name: 'VehicleHomePage',
  isPublic: false,
  nav: NAV_TYPES.NONE,
  icon: NullCmp,
  redirect: '/vehicle/list',
  routes: [
    {
      path: '/add',
      title: 'Add vehicle',
      name: 'VehicleAddPage',
      nav: NAV_TYPES.DRAWER,
      icon: DriveEtaSharpIcon,
    },
    {
      path: '/list',
      title: 'My vehicles',
      name: 'VehicleListPage',
      nav: NAV_TYPES.DRAWER,
      icon: HouseSharpIcon,
    },
    {
      path: '/edit',
      title: 'Edit',
      name: 'VehicleEditPage',
      nav: NAV_TYPES.NONE,
      icon: NullCmp,
    },
  ],
}

const _404 = {
  path: '/not_found',
  title: 'Not Found',
  isPublic: true,
  name: 'NotFoundPage',
  nav: NAV_TYPES.NONE,
  icon: NullCmp,
}

const recursiveRouteFunc = (parentRoutes: IRoute[], root = ''): IRoute[] => {
  return parentRoutes.flatMap((thisRoute) => {
    // If subroutes exist, return the parent route after the subroutes
    // Just to make sure subroutes display (ie. exact doesn't work)
    const route = {
      ...thisRoute,
      path: root + thisRoute.path,
    }
    if (thisRoute.routes) {
      return [...recursiveRouteFunc(thisRoute.routes, thisRoute.path), route]
    } else {
      return [route]
    }
  })
}

export const ROUTES = recursiveRouteFunc([HOME, LOGIN, LOGOUT, REFILL_HOME, VEHICLE_HOME, _404])
export const DRAWER_ROUTES = ROUTES.filter((r) => r.nav === NAV_TYPES.DRAWER)
export const USER_ROUTES = ROUTES.filter((r) => r.nav === NAV_TYPES.USER)

export const LOGIN_ROUTE = LOGIN.path
export const REFILL_REDIRECT_ROUTE = REFILL_HOME.redirect
export const VEHICLE_REDIRECT_ROUTE = VEHICLE_HOME.redirect
export const LOGIN_CB_ROUTE = REFILL_REDIRECT_ROUTE
export const LOGOUT_CB_ROUTE = HOME.path
export const NOT_FOUND_ROUTE = _404.path
