import { format, roundToNearestMinutes } from 'date-fns'

import { DATE_FORMAT, TIME_FORMAT } from 'util/constants/date'

export const getDateNow = (): string => format(Date.now(), DATE_FORMAT.MACHINE)
export const getTimeNow = (): string =>
  format(roundToNearestMinutes(Date.now(), { nearestTo: 5 }), TIME_FORMAT.T24)

// TODO: Could add format str param for parsing different date formats
export const getTimestamp = (date: string | Date): number => new Date(date).getTime()
